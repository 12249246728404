.services__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.card.service {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.service__icon {
  padding: 0.6rem;
  font-size: 1.5rem;
  background: var(--color-primary);
  border-radius: var(--border-radius-3);
  color: var(--color-white);
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
}

.service__icon svg:hover {
  animation: refresh 1s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes refresh {
  from {
    transform: scale(1.5) rotate(0);
  }
  to {
    transform: scale(1.5) rotate(360deg);
  }
}

.service:hover .service__icon {
  background: var(--color-light);
  color: var(--color-primary);
}

/* Media Query (Tablet)  */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}

/* Media Query (Phone)  */
@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .card.service {
    gap: 1.2rem;
    flex-direction: column;
    text-align: center;
  }
}
