header {
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
  background: var(--color-white);
  transition: var(--transition);
}

.tajmahal {
  background-image: linear-gradient(
    0deg,
    #138808 33%,
    #ffffff 33%,
    #ffffff 66%,
    #ff9933 66%
  );
  color: #fff;
  border-radius: 20%;
}

.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header__profile {
  width: 10rem;
  margin-bottom: 2rem;
  border-radius: 15rem;
  position: relative;
  cursor: pointer;
}
.verified {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.verified-img {
  width: 2.2rem;
}

.header__container p {
  width: 30rem;
  text-align: center;
  margin: 0.6rem 0.2rem 1.8rem;
}

.header__cta {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.header__socials {
  position: absolute;
  left: 0;
  bottom: 30%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header__socials a {
  width: 2.2rem;
  font-size: 1.2rem;
  aspect-ratio: 1/1;
  background: var(--color-light);
  color: var(--color-black);
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-3);
}

.header__skills {
  display: flex;
  gap: 1rem;
  margin: 0rem 0 2rem;
}

.header__skills svg {
  display: grid;
  place-items: center;
  background: whitesmoke;
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  padding: 0.3rem;
  border-radius: 50%;
  cursor: pointer;
}

.header__skills svg:hover {
  animation: refresh 1s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes refresh {
  from {
    transform: scale(1.5) rotate(0);
  }
  to {
    transform: scale(1.5) rotate(360deg);
  }
}

.header__skills svg:nth-child(1) {
  color: #f06529;
}
.header__skills svg:nth-child(2) {
  color: #2965f1;
}
.header__skills svg:nth-child(3) {
  color: #ffd133;
}
.header__skills svg:nth-child(4) {
  color: #cc6699;
}
.header__skills svg:nth-child(5) {
  color: #118caa;
}
.header__skills svg:nth-child(6) {
  color: #0a0c10;
}

.header__socials a:hover {
  background: var(--color-primary);
  color: var(--color-white);
  transform: translateX(-0.5rem);
}

/* Media Query (Phones)  */
@media screen and (max-width: 600px) {
  header {
    height: calc(100vh - 5rem) !important;
  }
  .header__profile {
    width: 8rem !important;
    max-width: 60%;
    max-height: 35vh;
  }

  .header__profile .profile-img {
    width: 80%;
    margin: 0 auto;
  }

  .verified-img {
    width: 20%;
  }

  .header__container {
    margin-bottom: 6.5rem;
  }

  .header__container p {
    width: 100%;
  }
  .header__cta {
    gap: 1rem;
  }

  .header__socials {
    display: none;
  }
  .verified-img {
    width: 1.8rem;
  }
  .verified {
    right: 0.5rem !important;
  }
}

/* Media Query (Tablets)  */
@media screen and (max-width: 1024px) {
  header {
    height: calc(75vh - 10rem);
  }
  .header__profile {
    width: 13rem;
  }
  .verified {
    right: 1rem;
  }
}
