#testimonials {
  padding-bottom: 0;
}

.swiper {
  padding-top: 1rem;
  height: fit-content;
  padding-bottom: 12rem;
}

.swiper-slide {
  cursor: grab;
}

.read-btn {
  color: var(--color-primary);
  padding: 0.5rem 0;
  font-weight: 500;
  background: transparent;
}

.testimonial__client {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.testimonial__client-avatar {
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: var(--border-radius-1);
  overflow: hidden;
  transition: var(--transition);
}

.swiper-pagination {
  margin-bottom: 8rem;
}

.swiper-pagination-bullet {
  background: var(--color-primary);
}
