nav {
  width: 100vw;
  height: 5rem;
  display: grid;
  place-items: center;
  background: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%);
  transition: var(--transition);
}

.nav__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav__logo {
  color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.nav__logo span {
  background-color: whitesmoke;
  border-radius: 0.3rem;
  padding: 0rem 0.4rem;
  color: var(--color-primary);
  margin-left: 0.2rem;
  font-weight: 600;
}

.nav__menu {
  display: flex;
  gap: 3.5rem;
}

.nav__menu a {
  color: white;
}

#theme__icon {
  background: transparent;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  vertical-align: middle;
}

/* Hide Nav Menu on Tablets and Phones  */
@media screen and (max-width: 1024px) {
  .nav__menu {
    display: none;
  }
}
